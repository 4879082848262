@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }

  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class^="pe-"] {
  font-size: 40px;
}
.all-icons input {
  border: 0;
}
.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}
.all-icons .font-icon-container a {
  padding: 5px 0;
  display: block;
  color: #666666;
}
.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}
.all-icons h5 {
  margin-top: 60px;
}
.all-icons > div > .row:first-child h5 {
  margin-top: 15px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.buttons-with-margin .btn {
  margin: 5px;
}

.buttons-with-margin .btn-group .btn {
  margin: 5px 0px 0px 0px;
}

.animated .img-rounded {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
  display: block;
}
.animated .img-rounded img {
  width: 100%;
}
.heart {
  color: #eb5e28;
  animation: heathing 1s ease infinite;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.ride-filter .checkbox {
  margin-top: 0;
  font-size: medium;
}

@keyframes heathing {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

div.marker-1 {
  opacity: 0;
  animation: blinking 1s linear infinite;
}

@keyframes blinking {
  from,
  49.9% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}

div.main-panel.full-width {
  width: 100% !important;
}

div.error input[type="text"] {
  border-color: #fb404b;
}

div.pending,
h5.pending {
  background-color: #96018c;
  padding: 10px;
  color: #e5e5e5;
}

div.incoming,
h5.incoming {
  background-color: #17a2b8;
  padding: 10px;
  color: #e5e5e5;
}

div.onway,
h5.onway {
  background-color: #007bff;
  padding: 10px;
  color: #e5e5e5;
}

div.waiting,
h5.waiting {
  background-color: #ff9900;
  padding: 10px;
  color: #000000;
}

div.onboard,
h5.onboard {
  background-color: #00cc66;
  padding: 10px;
  color: #000000;
}

div.canceled,
h5.canceled {
  background-color: #cc0000;
  padding: 10px;
  color: #e5e5e5;
}

div.completed,
h5.completed {
  background-color: #6c757d;
  padding: 10px;
  color: #f8f9fa;
}

div.disconnected {
  background-color: #6c757d;
  padding: 10px;
  color: #f8f9fa;
}

div.offline {
  background-color: #ecac0a;
  padding: 10px;
  color: #f8f9fa;
}

div.free {
  background-color: #117f04;
  padding: 10px;
  color: #f8f9fa;
}

div.busy {
  background-color: #9a0505;
  padding: 10px;
  color: #f8f9fa;
}

div.invoked {
  background-color: #052dbe;
  padding: 10px;
  color: #f8f9fa;
}
